import { interfaces } from "~/api";
import { useSelector } from "~/store";
import { AgreementLayout, AgreementReader } from "@kenai/core";
import MapTextPartsToComponent from "../map-text-parts-to-component";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";

/** @deprecated TODO: replace this with AgreementReaderProps */
interface AgreementProps {
  onAgreementContinue?: () => void;
  onAgreementAcceptToggle?: (checked: boolean) => void;

  agreementDetails: interfaces["VISITOR_AGREEMENT"];
  entityConfig: interfaces["ENTITY_CONFIG"];
  mandatoryAgreementRead: boolean;
  headingText?: string;
  subHeadingText?: string;
  agreementLinkText?: string;
  disabled?: boolean;
  hideCompanyLogoOnLanding?: boolean;
}

const AGREEMENT_COOKIE = "kenai-visitor-app-agreement";

export default function Agreement(props: AgreementProps) {
  // set ready state when document is finished loading
  const [ready, setReady] = useState(false);

  useEffect(() => {
    // This is a HACK to avoid a race condition in the state management
    // when the user clicks on the the continue button before the state
    // is ready, it will cause the phone number input not to be ready.
    // see https://notion.so/KNT-1443

    let timeout: NodeJS.Timeout;
    function handler() {
      timeout = setTimeout(() => {
        setReady(true);
      }, 1000);
    }
    if (document.readyState === "complete") {
      handler();
    } else {
      window.addEventListener("load", handler);
      return () => {
        clearTimeout(timeout);
        document.removeEventListener("load", handler);
      };
    }
  }, []);

  const entityAgreementIdentifier = useSelector(
    (state) => state.entityConfig.agreementIdentifier
  );
  const agreementValue = `${entityAgreementIdentifier}_${props.agreementDetails.AgreementVersion}`;

  return (
    <AgreementLayout>
      <AgreementReader
        dialogTitle="Visitors Agreement"
        headingText={
          props.headingText || "Welcome to our visitor pre-registration"
        }
        cookieName={AGREEMENT_COOKIE}
        identifier={agreementValue}
        companyLogo={
          !props.hideCompanyLogoOnLanding ? props.entityConfig.logo : undefined
        }
        companyName={props.entityConfig.entityName}
        mandatoryRead={props.mandatoryAgreementRead}
        agreementLinkText={props.agreementLinkText}
        onAgreementContinue={props.onAgreementContinue}
        onAgreementAcceptToggle={props.onAgreementAcceptToggle}
        disabled={props.disabled}
        slotProps={{
          continueButton: {
            disabled: !ready,
            startIcon: !ready ? <CircularProgress size={20} /> : undefined,
          },
        }}
      >
        <MapTextPartsToComponent
          textParts={props.agreementDetails.agreementTextParts}
          replacements={[
            {
              regex: new RegExp("{{companyName}}", "g"),
              value: props.agreementDetails.companyName,
            },
          ]}
        />
      </AgreementReader>
    </AgreementLayout>
  );
}
